import { reactive } from 'vue'
import mitt from 'mitt'
import { get, isObject, isEmpty, pick } from 'lodash-es'
import { useRoute } from 'nuxt/app'
import { FetchError } from 'ofetch'
import type {
  ProfileData,
  AuthData,
  AuthEvents,
  LoginPayload,
  UserData,
  VerifyPayload,
} from './auth-types'
import { getUserPayloadFromCookieHeader } from '#build/auth/auth.utils'

const LOGIN_PAYLOAD_KEY = 'owners/login/payload' as const

const PERSISTED_USER_STATE_KEY = '__auth__' as const
const PERSISTED_PROFILE_FIELD_KEY = '__profile__' as const

const PROFILE_FIELDS: (keyof ProfileData)[] = [
  'userID',
  'salutation',
  'username',
  'fullName',
  'firstName',
  'name',
  'emailAddress',
  'phoneNumber',
  'mobileNumber',
  'postalAddress',
  'permissions',
  'iban',
  'paymentSchedule',
  'tracking',
  'contractsYears',
  'taxId',
  'isEUAccount',
  'legalForm',
]

export default defineNuxtPlugin({
  name: 'auth:plugin',
  enforce: 'pre',
  async setup(nuxtApp) {
    const route = useRoute()
    const requestFetch = useRequestFetch()
    let persistedUser: UserData | null = null
    let persistedProfile: ProfileData | null = null

    if (import.meta.client && nuxtApp.payload) {
      persistedUser =
        (nuxtApp.payload[PERSISTED_USER_STATE_KEY] as UserData) || null
      persistedProfile =
        (nuxtApp.payload[PERSISTED_PROFILE_FIELD_KEY] as ProfileData) || null
    }

    const headers = useRequestHeaders(['cookie'])

    // In order to re-render components on changing auth context properties
    // It needs to wrap object in Observable wrapper
    const $auth = reactive<AuthData>({
      loading: false,
      loggedIn: false,
      userID: null,
      user: null,
      error: false,
      profile: {
        present: false,
        loading: false,
      },
      loginPayload: {
        token: null,
        email: null,
      },
      maskedEmail: null,
      verification: {
        error: false,
        triesLeft: 3,
      },
      uid: null,
    })

    nuxtApp.provide('auth', $auth)

    if (route.query.uid) {
      $auth.uid = route.query.uid as string
    }

    function setProfileData(profileData: ProfileData | null) {
      if (import.meta.server) {
        nuxtApp.payload[PERSISTED_PROFILE_FIELD_KEY] = profileData
      }

      // on getting null
      if (!isObject(profileData) || isEmpty(profileData)) {
        $auth.profile.present = false
        PROFILE_FIELDS.forEach((key) => delete $auth.profile[key])
        return
      }

      Object.assign($auth.profile, pick(profileData, PROFILE_FIELDS))

      Object.assign($auth.profile, {
        present: true,
        get postalAddressLines() {
          if (!$auth.profile.present || !$auth.profile.postalAddress) return []
          const postalAddress = $auth.profile.postalAddress
          return [
            postalAddress.street,
            `${postalAddress.zip} ${postalAddress.place}`,
            postalAddress.country,
          ].filter(Boolean)
        },
      })
    }

    const authEvents = mitt<AuthEvents>()

    function resetAuthentication() {
      Object.assign($auth, {
        loading: false,
        loggedIn: false,
        userID: null,
        user: null,
        error: false,
        maskedEmail: null,
      })

      setLoginPayload(null)

      Object.assign($auth.profile, {
        loading: false,
      })

      setProfileData(null)

      Object.assign($auth.verification, {
        error: false,
        triesLeft: 3,
      })
    }

    if (import.meta.server) {
      const jwtUser = headers
        ? getUserPayloadFromCookieHeader(headers.cookie || '')
        : null

      if (jwtUser) {
        const persistedUserData = Object.assign(
          pick(jwtUser, ['username', 'requireNewPassword']),
          {
            userID: jwtUser.userid,
          },
        ) as unknown as UserData
        $auth.user = persistedUserData
        $auth.userID = persistedUserData.userID
        $auth.loggedIn = true

        if (import.meta.server) {
          nuxtApp.payload[PERSISTED_USER_STATE_KEY] = persistedUserData
        }

        await fetchProfile()
      }
    }

    function setLoginPayload(payload: LoginPayload | null) {
      const { token = null, email = null } = payload || {}
      Object.assign($auth.loginPayload, {
        token,
        email,
      })
      if (import.meta.server) return
      if (email) {
        $auth.maskedEmail = email
      }
      if ($auth.loginPayload.token) {
        localStorage.setItem(
          LOGIN_PAYLOAD_KEY,
          JSON.stringify($auth.loginPayload),
        )
      } else {
        localStorage.removeItem(LOGIN_PAYLOAD_KEY)
      }
    }

    if (import.meta.client) {
      if (isObject(persistedUser) && 'username' in persistedUser) {
        $auth.loggedIn = true
        $auth.userID = persistedUser.userID
        $auth.user = persistedUser
      }

      setProfileData(persistedProfile)

      const loginPersistentPayload = JSON.parse(
        localStorage.getItem(LOGIN_PAYLOAD_KEY) || 'null',
      ) as LoginPayload

      if (
        isObject(loginPersistentPayload) &&
        !!loginPersistentPayload?.token &&
        !!loginPersistentPayload?.email
      ) {
        $auth.loginPayload.token = loginPersistentPayload.token
        $auth.loginPayload.email = loginPersistentPayload.email
        $auth.maskedEmail = loginPersistentPayload.email
      } else {
        $auth.loginPayload.token = null
        $auth.loginPayload.email = null
      }
    }

    authEvents.on('logout', () => {
      resetAuthentication()
    })

    authEvents.on('login-finish', async (userData: UserData) => {
      setLoginPayload(null)
      await fetchProfile()
      const { $unleash } = useNuxtApp()
      const showTaxIdBanner =
        !$auth.profile.taxId &&
        $auth.profile.isEUAccount &&
        $unleash.features['profile-legal'] &&
        $unleash.features['tax-id-page']

      if (userData.requireNewPassword) {
        navigateTo('/profile/change-password')
      } else if (showTaxIdBanner) {
        navigateTo({
          path: '/tax-id',
          query: { rdu: route.query.rdu as string },
        })
      } else if (route.query.rdu) {
        navigateTo(route.query.rdu as string)
      } else {
        navigateTo('/')
      }
    })

    async function fetchProfile() {
      try {
        $auth.profile.loading = true
        const profile = await requestFetch<ProfileData>('/api/profile')
        setProfileData(profile)
      } catch (e) {
        console.error('AuthModule.fetchProfile Error', e)
      } finally {
        $auth.profile.loading = false
      }
    }

    async function login(userID: string, password: string) {
      resetAuthentication()

      try {
        $auth.userID = userID
        $auth.loading = true

        const data = await requestFetch<LoginPayload>('/api/login', {
          method: 'POST',
          body: {
            userID,
            password,
          },
        })

        if (data.success) {
          setLoginPayload(data)

          authEvents.emit('login', { userID, maskedEmail: data.email! })

          if (data.noEmail) {
            navigateTo('/authentication-error')
          } else {
            navigateTo({
              path: '/2fa',
              query: { rdu: route.query.rdu as string },
            })
          }
        } else {
          $auth.error = true
        }
      } catch (e) {
        console.error(e)
        $auth.error = true
      } finally {
        $auth.loading = false
        if ($auth.error) {
          authEvents.emit('login-fail', $auth.userID!)
        }
      }
    }

    async function logout(logoutRoutePath = '/login') {
      try {
        await requestFetch<void>('/api/logout', { method: 'POST' })
        authEvents.emit('logout', $auth.userID!)
      } catch (e) {
        console.error('Cannot logout', e)
      } finally {
        resetAuthentication()
        // Redirect to login page
        await navigateTo(logoutRoutePath)
      }
    }

    async function resendToken() {
      if ($auth.loading) {
        return false
      }
      $auth.loading = true
      if ($auth.loggedIn) {
        return await verificationStart()
      } else {
        return await resend2faToken()
      }
    }

    async function resend2faToken() {
      let result = false
      try {
        const token = $auth.loginPayload.token

        const data = await requestFetch<LoginPayload>('/api/login/resend', {
          method: 'post',
          body: {
            token,
          },
        })

        if (data.success) {
          result = true

          // update token & email
          setLoginPayload(data)

          authEvents.emit('token-resend', $auth.loginPayload.email!)
        }
      } catch (e) {
        console.error(e)
      } finally {
        $auth.loading = false
      }
      return result
    }

    async function verificationStart() {
      try {
        const { success, maskedEmail, triesLeft } =
          await requestFetch<VerifyPayload>('/api/verification/start', {
            method: 'POST',
          })
        $auth.maskedEmail = maskedEmail
        if (success) {
          $auth.verification.error = false
          $auth.verification.triesLeft = triesLeft
        }
        return success
      } catch (e) {
        console.error(e)
      } finally {
        $auth.loading = false
      }
      return false
    }

    async function verifyToken(code: string) {
      if ($auth.verification.triesLeft <= 0) {
        return false
      }

      $auth.loading = true
      $auth.verification.error = false

      if ($auth.loggedIn) {
        return await verifyProfileToken(code)
      } else {
        return await verify2faToken(code)
      }
    }

    async function verify2faToken(code: string) {
      try {
        const token = $auth.loginPayload.token

        const data = await requestFetch<{
          success: boolean
          username: string
          requireNewPassword: boolean
        }>('/api/login/verify', {
          method: 'post',
          body: {
            token,
            code,
          },
        })

        if (data.success) {
          const userData = Object.freeze(
            ($auth.user = {
              userID: $auth.userID!,
              username: data.username,
              requireNewPassword: data.requireNewPassword,
            }),
          )

          $auth.loggedIn = true

          authEvents.emit('login-finish', userData)
          return true
        }
      } catch (e) {
        $auth.loading = false
        $auth.verification.error = true
        if (e instanceof FetchError && [400, 401].includes(e.statusCode!)) {
          $auth.verification.triesLeft = get(e, 'data.triesLeft', 0)
        } else {
          console.error(e)
        }
      }
      return false
    }

    async function verifyProfileToken(code: string) {
      try {
        const { success, triesLeft } = await requestFetch<VerifyPayload>(
          '/api/verification/verify',
          {
            method: 'POST',
            body: {
              code,
            },
          },
        )

        if (!success) {
          $auth.loading = false
          $auth.verification.error = true
          $auth.verification.triesLeft = triesLeft
        }
        return success
      } catch (e) {
        $auth.loading = false
        $auth.verification.error = true
        if (e instanceof FetchError && e.statusCode! === 400) {
          $auth.verification.triesLeft = get(e, 'data.triesLeft', 0)
        } else {
          console.error(e)
        }
      }
      return false
    }

    Object.assign(
      $auth,
      {
        login,
        logout,
        resendToken,
        verifyToken,
        verificationStart,
        fetchProfile,
      },
      authEvents,
    )
  },
})
