import type { TrackEvent } from '~/composables/tracking'

function trackCard(trackEvent: TrackEvent = {}) {
  track({
    action: TrackAction.Open,
    component: 'sustainability-card',
    event: 'sustainability',
    label: getI18nLabel('components.common.learnMore'),
    ...trackEvent,
  })
}

function trackPopup(trackEvent: TrackEvent = {}) {
  track({
    action: TrackAction.View,
    component: 'sustainability-popup',
    event: 'sustainability',
    label: getI18nLabel('components.sustainability.modal.submitBtn'),
    ...trackEvent,
  })
}

export default { trackCard, trackPopup }
