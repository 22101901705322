export enum TrackProfileEventType {
  IBAN = 'IBAN',
  PaymentSchedule = 'PaymentSchedule',
}

export enum TravellerContactType {
  MOB = 'MOB',
  PHONE = 'TELP',
  PHONE_BUSINESS = 'TELB',
  EMAIL = 'EMAIL',
}

export enum PaymentScheduleStep {
  PaymentScheduleChange = 1,
  PaymentScheduleConfirmation = 2,
  PaymentSchedule2fa = 3,
}

export enum FormType {
  Update = 'update',
  Create = 'create',
}
