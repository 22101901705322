import type { TrackEvent } from '~/composables/tracking'

function getLabel(feature: FeatureNotification) {
  const { $ROUTE } = useNuxtApp()
  let label
  if (feature.url === $ROUTE.PROPERTY_BENCHMARK) {
    label = getI18nLabel('components.common.propertyBenchmark')
  } else if (feature.url === $ROUTE.PROFILE_PAYMENT_INFO) {
    label = getI18nLabel('components.common.paymentInfo')
  }
  return label
}

function trackCard(feature: FeatureNotification, trackEvent: TrackEvent = {}) {
  track({
    action: TrackAction.Open,
    category: 'New feature',
    component: 'inbox-card',
    event: 'inbox',
    label: getLabel(feature),
    ...trackEvent,
  })
}

function trackPopup(feature: FeatureNotification, trackEvent: TrackEvent = {}) {
  track({
    action: TrackAction.View,
    category: 'New feature',
    component: 'inbox-popup',
    event: 'inbox',
    label: getLabel(feature),
    ...trackEvent,
  })
}

export default { trackCard, trackPopup }
