<script setup lang="ts">
import { IconBtn } from 'webcc-ui-components'

const route = useRoute()
const { $ROUTE } = useNuxtApp()
const { trackInboxBellClick } = useHeaderTracking()

const isNotificationIconVisible = useState(
  'isNotificationIconVisible',
  () => false,
)

const isInboxPage = computed(() => route.name === 'inbox')
const isBadgeVisible = computed(() => {
  return isNotificationIconVisible.value && !isInboxPage.value
})

function openInbox() {
  trackInboxBellClick(isBadgeVisible.value)
  navigateTo($ROUTE.INBOX)
  useActiveElement().value?.blur()
}
</script>

<template>
  <div class="relative">
    <IconBtn
      icon="notification"
      class="inbox-icon"
      variant="neutral"
      :is-active="isInboxPage"
      @click="openInbox"
    />
    <ClientOnly>
      <div v-if="isBadgeVisible" class="absolute -top-1 right-2">
        <div
          class="absolute left-0 bg-cta grid place-items-center rounded-full h-[0.9375rem] min-w-[0.9375rem]"
        />
      </div>
    </ClientOnly>
  </div>
</template>
