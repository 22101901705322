import { h, render } from 'vue'
import { defineNuxtPlugin } from '#app'
import type { NuxtApp } from '#app'
import type { PwaBanner } from './types'
import { isStandaloneApplication } from '~/utils'

const PWA_HIDE_KEY = 'PWA/Hide'
const PWA_STORAGE_TIMEOUT = 45 * 24 * 60 * 60 * 1000 // 45 days in ms

export default defineNuxtPlugin({
  name: 'pwa/banner-plugin',
  setup(nuxtApp) {
    const PwaBannerComponent = defineAsyncComponent(
      () => import('~/modules/pwa/runtime/components/PwaBanner.vue'),
    )

    const { $auth, $consent, $device } = nuxtApp as NuxtApp

    const timer = useLocalStorage<string>(PWA_HIDE_KEY, '0')

    const isPromptNotVisible = ref<boolean>(
      isStandaloneApplication() || parseInt(timer.value, 10) > Date.now(),
    )

    const $pwaBanner = reactive<PwaBanner>({
      get visible() {
        return (
          $auth.loggedIn &&
          !$consent.isOpened &&
          ($device.isIOS || $device.isAndroid
            ? !isPromptNotVisible.value
            : false)
        )
      },
      get showed() {
        return (
          $auth.loggedIn &&
          !$consent.isOpened &&
          ($device.isIOS || $device.isAndroid ? isPromptNotVisible.value : true)
        )
      },
      close() {
        isPromptNotVisible.value = true
        timer.value = String(Date.now() + PWA_STORAGE_TIMEOUT)
      },
    })

    Object.defineProperty(nuxtApp.vueApp, '$pwaBanner', {
      get: () => $pwaBanner,
    })
    nuxtApp.provide('pwaBanner', $pwaBanner)
    nuxtApp.vueApp.component('PwaBanner', PwaBannerComponent)

    nuxtApp.hooks.hook('app:mounted', (app) => {
      let pwaBannerTarget = document.getElementById(
        'pwa-banner-target',
      ) as HTMLDivElement
      if (!pwaBannerTarget) {
        pwaBannerTarget = document.createElement('div')
        pwaBannerTarget.setAttribute('id', 'pwa-banner-target')
      }
      const pwaBannerNodeTree = h(PwaBannerComponent)
      pwaBannerNodeTree.appContext = app._context
      document.body.appendChild(pwaBannerTarget)
      render(pwaBannerNodeTree, pwaBannerTarget)
    })
  },
})
