import { useQuery } from '@tanstack/vue-query'
import type { MaybeRef } from 'vue'
import { type components, NotificationType } from '~/schema'

export type InboxNotificationResponse =
  components['schemas']['NotificationsResponse']
export type InboxStatusResponse = components['schemas']['InboxStatus']
export type ReservationNotification =
  components['schemas']['ReservationNotification']

export type RenewalNotification = components['schemas']['RenewalNotification']
export type ContractNotification = components['schemas']['ContractNotification']
export type FeatureNotification = components['schemas']['FeatureNotification']
export type Notification = components['schemas']['Notification']

export type NotificationCard = Omit<
  components['schemas']['Notification'],
  'reservation'
> &
  (
    | ({
        type: NotificationType.RESERVATION
      } & Required<Pick<Notification, 'reservation'>>)
    | ({
        type: NotificationType.RENEWAL
      } & Required<Pick<Notification, 'renewal'>>)
    | ({
        type: NotificationType.CONTRACT
      } & Required<Pick<Notification, 'contract'>>)
    | ({ type: NotificationType.FEATURE } & Required<
        Pick<Notification, 'featureNotification'>
      >)
  )

export function useNotificationsQuery() {
  const fetch = useApiFetch()
  const { locale } = useI18n()
  return useQuery({
    queryKey: ['inbox-notifications', locale] as const,
    queryFn({ signal }) {
      return fetch<InboxNotificationResponse>('/api/inbox/notifications', {
        signal,
      })
    },
    staleTime: 20 * 1000, // 20 second delay
    placeholderData: (previousData) => previousData,
    select(data: InboxNotificationResponse): Notification[] {
      return data.content ?? ([] as Notification[])
    },
  })
}

export async function useInboxNotificationsQuery() {
  const isNotificationIconVisible = useState(
    'isNotificationIconVisible',
    () => false,
  )

  const queryResult = await useTryCatchSuspense(useNotificationsQuery)

  watchImmediate(queryResult.data, () => {
    isNotificationIconVisible.value = false
  })

  return queryResult
}

export function useFetchInboxStatus(params: { enabled: MaybeRef<boolean> }) {
  const fetch = useApiFetch()

  const isNotificationIconVisible = useState(
    'isNotificationIconVisible',
    () => false,
  )

  const queryResult = useQuery({
    staleTime: 0,
    enabled: import.meta.client && params.enabled,
    queryKey: ['inbox-status'],
    queryFn({ signal }) {
      return fetch<InboxStatusResponse>('/api/inbox/status', { signal })
    },
  })

  import.meta.client &&
    watch(
      queryResult.data,
      (data) =>
        (isNotificationIconVisible.value = data?.newNotifications ?? false),
    )

  return queryResult
}

export type UnifiedRenewalNotification = Extract<
  NotificationCard,
  { type: NotificationType.RENEWAL | NotificationType.CONTRACT }
>

export const featureData = shallowRef<FeatureNotification>()

export function openFeatureInboxPopup(data: FeatureNotification) {
  featureData.value = data
}

export function closeFeatureInboxPopup() {
  featureData.value = undefined
}

export function useHandleInboxCard() {
  const { open: openReservation, trackReservation } = useReservationPopup()
  const notificationData = shallowRef<UnifiedRenewalNotification>()

  function open(data: UnifiedRenewalNotification) {
    notificationData.value = data
  }

  return {
    close() {
      notificationData.value = undefined
    },
    notificationData,
    handleInboxCard(notification: NotificationCard) {
      if (notification.type === NotificationType.FEATURE) {
        const feature = notification.featureNotification
        tracking.feature.trackCard(feature)
        return openFeatureInboxPopup(feature)
      }

      if (notification.type === NotificationType.RESERVATION) {
        trackReservation()
        return openReservation(notification.reservation.resNo)
      }

      open(notification)
      const { inboxContracts } = useContractPriceTracking()

      if (notification.type === NotificationType.RENEWAL) {
        inboxContracts.autoRenewClick(notification)
        return
      }

      if (notification.type === NotificationType.CONTRACT) {
        inboxContracts.manualRenewClick(notification)
      }
    },
  }
}
