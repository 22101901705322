import type { TrackEvent } from '~/composables/tracking'

function trackCard(trackEvent: TrackEvent = {}) {
  track({
    action: TrackAction.Open,
    component: 'my-rent-card',
    event: 'my_rent',
    label: getI18nLabel('components.common.learnMore'),
    ...trackEvent,
  })
}

function trackPopup(trackEvent: TrackEvent = {}) {
  track({
    action: TrackAction.View,
    component: 'my-rent-popup',
    event: 'my_rent',
    label: getI18nLabel('components.myRent.modal.btn', {
      myRent: 'components.myRent.myRent',
    }),
    ...trackEvent,
  })
}

function trackBackToPreviousPopup(trackEvent: TrackEvent = {}) {
  track({
    action: TrackAction.Back,
    component: 'opportunities',
    event: 'opportunities',
    label: 'Back Button',
    ...trackEvent,
  })
}

function trackShareAccommodationLinkPopup(trackEvent: TrackEvent = {}) {
  track({
    action: TrackAction.View,
    component: 'opportunities',
    event: 'opportunities',
    ...trackEvent,
  })
}

export default {
  trackCard,
  trackPopup,
  trackBackToPreviousPopup,
  trackShareAccommodationLinkPopup,
}
