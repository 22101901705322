<script setup lang="ts">
const { locale } = useI18n()
const { $auth, $ROUTE } = useNuxtApp()

const link = computed(() =>
  $auth?.loggedIn
    ? $ROUTE.INDEX
    : 'https://www.interhome.group/'.concat(locale.value ?? ''),
)

const target = computed(() => ($auth?.loggedIn ? '_self' : '_blank'))
</script>

<template>
  <div class="flex items-center">
    <NuxtLink :target="target" :to="link">
      <img
        height="40"
        width="152"
        src="~/assets/icons/interhome.svg"
        alt="Interhome Logo"
      />
    </NuxtLink>
  </div>
</template>
