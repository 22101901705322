import {
  ContractNotificationContractStatus,
  RenewalNotificationProcessType,
} from '~/schema'
import type { NotificationCard } from '~/composables/inbox'

export enum ContractPriceActions {
  requestNow = 'Request now',
  checkStatus = 'Check status',
  haveQuestion = 'Have questions?',
}

export const useContractPriceTracking = () => {
  const { $gtm, $i18n, $ROUTE } = useNuxtApp()
  const route = useRoute()
  const commonParams = {
    category: undefined,
    _clear: true,
  }

  function getDynamicPriceTracking() {
    const dynamicPriceParams = {
      ...commonParams,
      event: 'dynamic_pricing',
      component: 'dynamic-pricing',
    }

    const positionsMap = {
      [$ROUTE.BOOKINGS_CALENDAR]: 'Calendar',
      [$ROUTE.INDEX]: 'Dashboard',
      [$ROUTE.OPPORTUNITIES]: 'Opportunities',
    }

    return {
      trackDynamicPriceModalOpen: (isActivation: boolean) => {
        $gtm.trackEvent({
          action: 'view card',
          label: isActivation
            ? 'Activate Dynamic Pricing'
            : 'Deactivate Dynamic Pricing',
          position: positionsMap[route.path],
          language: unref($i18n.locale),
          ...dynamicPriceParams,
        })
      },
      trackDynamicPriceAction: (isActivation: boolean) => {
        $gtm.trackEvent({
          action: isActivation ? 'activate' : 'deactivate',
          label: isActivation
            ? 'Activate Dynamic Pricing'
            : 'Deactivate Dynamic Pricing',
          position: positionsMap[route.path],
          language: unref($i18n.locale),
          ...dynamicPriceParams,
        })
      },
    }
  }

  function getContractPriceTracking() {
    const contractPriceParams = {
      ...commonParams,
      event: 'contract_prices',
      component: 'contract-prices',
    }

    return {
      trackContractPriceOpenModal: (label: ContractPriceActions) => {
        $gtm.trackEvent({
          action: 'view card',
          label,
          language: unref($i18n.locale),
          ...contractPriceParams,
        })
      },
      trackContractPriceRenew: () => {
        $gtm.trackEvent({
          action: 'renew',
          label: 'Renew prices',
          language: unref($i18n.locale),
          ...contractPriceParams,
        })
      },
    }
  }

  function getInboxTracking() {
    const contractPriceParams = {
      event: 'contract_prices',
      component: 'contract-prices',
      _clear: true,
    }
    return {
      autoRenewClick: (notification: NotificationCard) => {
        $gtm.trackEvent({
          action: 'open',
          label:
            notification.renewal!.processType ===
            RenewalNotificationProcessType.REQUEST
              ? 'Available contract prices'
              : 'Live contract prices',
          language: unref($i18n.locale),
          category: 'auto-renewal',
          position: 'inbox',
          ...contractPriceParams,
        })
      },
      manualRenewClick: (notification: NotificationCard) => {
        $gtm.trackEvent({
          action: 'open',
          label:
            notification!.contract!.contractStatus ===
            ContractNotificationContractStatus.ACTIVE
              ? 'Live contract prices'
              : 'Available contract prices',
          language: unref($i18n.locale),
          category: 'manual',
          position: 'inbox',
          ...contractPriceParams,
        })
      },
      manualRenewModalClick: (isContractStatusPending: boolean) => {
        $gtm.trackEvent({
          action: isContractStatusPending ? 'review' : 'view',
          label: isContractStatusPending
            ? 'Review contract prices'
            : 'See contract prices',
          language: unref($i18n.locale),
          category: 'manual',
          position: 'popup',
          ...contractPriceParams,
        })
      },
      autoRenewModalClick: (isProcessTypeRequest: boolean) => {
        $gtm.trackEvent({
          action: isProcessTypeRequest ? 'review' : 'view',
          label: isProcessTypeRequest
            ? 'Review contract prices'
            : 'See contract prices',
          language: unref($i18n.locale),
          category: 'auto-renewal',
          position: 'popup',
          ...contractPriceParams,
        })
      },
    }
  }

  return {
    dynamicPrice: getDynamicPriceTracking(),
    contractPrices: getContractPriceTracking(),
    inboxContracts: getInboxTracking(),
  }
}
