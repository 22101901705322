export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('i18n:beforeLocaleSwitch', async () => {
    showLocaleSpinner.value = true
    await new Promise((resolve) => setTimeout(resolve, 400))
  })

  nuxtApp.hook('i18n:localeSwitched', () => {
    showLocaleSpinner.value = false
  })
})
