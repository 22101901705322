import type { TrackEvent } from '~/composables/tracking'
import {
  ProfileResponseLegalForm,
  UpdateTaxDetailsRequestLegalForm,
} from '~/schema'

function getLabel(
  legalForm: ProfileResponseLegalForm | UpdateTaxDetailsRequestLegalForm,
) {
  return isVatNumber(legalForm)
    ? getI18nLabel('components.profile.legal.vatNumber')
    : getI18nLabel('components.profile.legal.tinNumber')
}

function trackCurrentTax(
  legalForm: ProfileResponseLegalForm | UpdateTaxDetailsRequestLegalForm,
  trackEvent: TrackEvent = {},
) {
  track({
    action: TrackAction.Select,
    category: 'Legal data',
    component: 'profile',
    event: 'tax_id',
    label: getLabel(legalForm),
    ...trackEvent,
  })
}

function trackSaveTax(
  legalForm: ProfileResponseLegalForm | UpdateTaxDetailsRequestLegalForm,
  trackEvent: TrackEvent = {},
) {
  track({
    action: TrackAction.Update,
    category: 'Legal data',
    component: 'profile',
    event: 'tax_id',
    label: getLabel(legalForm),
    ...trackEvent,
  })
}

function trackSaveLicense(trackEvent: TrackEvent = {}) {
  track({
    action: TrackAction.Edit,
    category: 'Legal data',
    component: 'profile',
    event: 'tax_id',
    ...trackEvent,
  })
}

function trackBanner(trackEvent: TrackEvent = {}) {
  track({
    action: TrackAction.BannerClick,
    component: 'tax-id',
    event: 'tax_id',
    ...trackEvent,
  })
}

export default {
  trackCurrentTax,
  trackSaveTax,
  trackSaveLicense,
  trackBanner,
}
