
// @ts-nocheck


export const localeCodes =  [
  "cs",
  "de",
  "en",
  "es",
  "fr",
  "hr",
  "it",
  "nl",
  "pl",
  "pt"
]

export const localeLoaders = {
  "cs": [{ key: "../src/translations/json/cs.json", load: () => import("../src/translations/json/cs.json" /* webpackChunkName: "locale__myapp_src_translations_json_cs_json" */), cache: true }],
  "de": [{ key: "../src/translations/json/de.json", load: () => import("../src/translations/json/de.json" /* webpackChunkName: "locale__myapp_src_translations_json_de_json" */), cache: true }],
  "en": [{ key: "../src/translations/json/en.json", load: () => import("../src/translations/json/en.json" /* webpackChunkName: "locale__myapp_src_translations_json_en_json" */), cache: true }],
  "es": [{ key: "../src/translations/json/es.json", load: () => import("../src/translations/json/es.json" /* webpackChunkName: "locale__myapp_src_translations_json_es_json" */), cache: true }],
  "fr": [{ key: "../src/translations/json/fr.json", load: () => import("../src/translations/json/fr.json" /* webpackChunkName: "locale__myapp_src_translations_json_fr_json" */), cache: true }],
  "hr": [{ key: "../src/translations/json/hr.json", load: () => import("../src/translations/json/hr.json" /* webpackChunkName: "locale__myapp_src_translations_json_hr_json" */), cache: true }],
  "it": [{ key: "../src/translations/json/it.json", load: () => import("../src/translations/json/it.json" /* webpackChunkName: "locale__myapp_src_translations_json_it_json" */), cache: true }],
  "nl": [{ key: "../src/translations/json/nl.json", load: () => import("../src/translations/json/nl.json" /* webpackChunkName: "locale__myapp_src_translations_json_nl_json" */), cache: true }],
  "pl": [{ key: "../src/translations/json/pl.json", load: () => import("../src/translations/json/pl.json" /* webpackChunkName: "locale__myapp_src_translations_json_pl_json" */), cache: true }],
  "pt": [{ key: "../src/translations/json/pt.json", load: () => import("../src/translations/json/pt.json" /* webpackChunkName: "locale__myapp_src_translations_json_pt_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "cs",
      "name": "Český",
      "files": [
        "/myapp/src/translations/json/cs.json"
      ]
    },
    {
      "code": "de",
      "name": "Deutsch",
      "files": [
        "/myapp/src/translations/json/de.json"
      ]
    },
    {
      "code": "en",
      "name": "English",
      "files": [
        "/myapp/src/translations/json/en.json"
      ]
    },
    {
      "code": "es",
      "name": "Español",
      "files": [
        "/myapp/src/translations/json/es.json"
      ]
    },
    {
      "code": "fr",
      "name": "Français",
      "files": [
        "/myapp/src/translations/json/fr.json"
      ]
    },
    {
      "code": "hr",
      "name": "Hrvatski",
      "files": [
        "/myapp/src/translations/json/hr.json"
      ]
    },
    {
      "code": "it",
      "name": "Italiano",
      "files": [
        "/myapp/src/translations/json/it.json"
      ]
    },
    {
      "code": "nl",
      "name": "Nederlands",
      "files": [
        "/myapp/src/translations/json/nl.json"
      ]
    },
    {
      "code": "pl",
      "name": "Polski",
      "files": [
        "/myapp/src/translations/json/pl.json"
      ]
    },
    {
      "code": "pt",
      "name": "Português",
      "files": [
        "/myapp/src/translations/json/pt.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "translations/json",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "cs",
    "name": "Český",
    "files": [
      {
        "path": "/myapp/src/translations/json/cs.json"
      }
    ]
  },
  {
    "code": "de",
    "name": "Deutsch",
    "files": [
      {
        "path": "/myapp/src/translations/json/de.json"
      }
    ]
  },
  {
    "code": "en",
    "name": "English",
    "files": [
      {
        "path": "/myapp/src/translations/json/en.json"
      }
    ]
  },
  {
    "code": "es",
    "name": "Español",
    "files": [
      {
        "path": "/myapp/src/translations/json/es.json"
      }
    ]
  },
  {
    "code": "fr",
    "name": "Français",
    "files": [
      {
        "path": "/myapp/src/translations/json/fr.json"
      }
    ]
  },
  {
    "code": "hr",
    "name": "Hrvatski",
    "files": [
      {
        "path": "/myapp/src/translations/json/hr.json"
      }
    ]
  },
  {
    "code": "it",
    "name": "Italiano",
    "files": [
      {
        "path": "/myapp/src/translations/json/it.json"
      }
    ]
  },
  {
    "code": "nl",
    "name": "Nederlands",
    "files": [
      {
        "path": "/myapp/src/translations/json/nl.json"
      }
    ]
  },
  {
    "code": "pl",
    "name": "Polski",
    "files": [
      {
        "path": "/myapp/src/translations/json/pl.json"
      }
    ]
  },
  {
    "code": "pt",
    "name": "Português",
    "files": [
      {
        "path": "/myapp/src/translations/json/pt.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
