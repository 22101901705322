import { h, render } from 'vue'
import { defineNuxtPlugin } from '#app'
import type { NpsBanner, NpsData } from '~/modules/nps/runtime/types'

const SHARE_FEEDBACK_STORAGE_KEY = 'ShareFeedbackBanner'

const SHARE_FEEDBACK_HIDE_TIME = 24 * 60 * 60 * 1000 // 1 day
const FEEDBACK_GIVEN_HIDE_TIME = 14 * 24 * 60 * 60 * 1000 // 14 days

export default defineNuxtPlugin({
  name: 'nps:plugin',
  setup(nuxtApp) {
    const NpsBannerComponent = defineAsyncComponent(
      () => import('~/modules/nps/runtime/NpsBanner.vue'),
    )
    const $nps = reactive<NpsBanner>({
      showed: getLocalStorageData(),
      close,
      vote,
      setFeedback,
    })

    Object.defineProperty(nuxtApp.vueApp, '$nps', { get: () => $nps })

    nuxtApp.provide('nps', $nps)
    nuxtApp.vueApp.component('NpsBanner', NpsBannerComponent)

    function close(): void {
      $nps.showed = false
    }

    function setFeedback(nps: NpsData): void {
      localStorage.setItem(SHARE_FEEDBACK_STORAGE_KEY, JSON.stringify(nps))
    }

    async function vote(rating: number): Promise<boolean> {
      const fetch = useApiFetch()
      try {
        await fetch('/api/rating', { method: 'POST', body: { rating } })
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    }

    nuxtApp.hooks.hook('app:mounted', (app) => {
      let npsTarget = document.getElementById('nps-target') as HTMLDivElement
      if (!npsTarget) {
        npsTarget = document.createElement('div')
        npsTarget.setAttribute('id', 'nps-target')
      }
      const npsNodeTree = h(NpsBannerComponent)
      npsNodeTree.appContext = app._context
      document.body.appendChild(npsTarget)
      render(npsNodeTree, npsTarget)
    })
  },
})

function getLocalStorageData(): boolean {
  const localStorageData = useLocalStorage<NpsData>(
    SHARE_FEEDBACK_STORAGE_KEY,
    { lastBoxClosingDate: undefined, feedbackGiven: undefined },
  )

  if (
    localStorageData.value.lastBoxClosingDate !== undefined &&
    localStorageData.value.feedbackGiven !== undefined
  ) {
    const currentDate = new Date()
    const pastDate = new Date(localStorageData.value.lastBoxClosingDate)

    const time = localStorageData.value.feedbackGiven
      ? FEEDBACK_GIVEN_HIDE_TIME
      : SHARE_FEEDBACK_HIDE_TIME

    const diff = currentDate.getTime() - pastDate.getTime()

    return Math.abs(diff) >= time
  }

  return true
}
