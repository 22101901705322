import { useMutation, useQuery } from '@tanstack/vue-query'
import type { DefaultError } from '@tanstack/query-core'
import {
  type components,
  ProfileResponseLegalForm,
  UpdateTaxDetailsRequestLegalForm,
} from '~/schema'

export type UpdateTaxDetails = components['schemas']['UpdateTaxDetailsRequest']

export function isVatNumber(
  legal: ProfileResponseLegalForm | UpdateTaxDetailsRequestLegalForm,
) {
  return legal === UpdateTaxDetailsRequestLegalForm.VAT_COMPANY
}

export function useProfileLegalTranslation(
  legalForm: MaybeRefOrGetter<
    ProfileResponseLegalForm | UpdateTaxDetailsRequestLegalForm
  >,
) {
  const { t } = useI18n()

  return {
    getDropdownValue(
      legal: ProfileResponseLegalForm | UpdateTaxDetailsRequestLegalForm,
    ) {
      return isVatNumber(legal)
        ? t('components.common.yes')
        : t('components.common.no')
    },
    label: computed(() => {
      if (!unref(legalForm)) return t('components.profile.legal.card.title')
      return isVatNumber(toValue(legalForm))
        ? t('components.profile.legal.vatNumber')
        : t('components.profile.legal.tinNumber')
    }),
  }
}

export function useTaxNumberLegalFormQuery() {
  return useQuery({
    queryKey: ['legal-forms'],
    queryFn: ({ signal }) => {
      return useApiFetch()<UpdateTaxDetailsRequestLegalForm[]>(
        '/api/payments/tax_details/legal_forms',
        {
          signal,
        },
      )
    },
  })
}

export function useUpdateTaxNumberMutation() {
  let abortController: AbortController | null = null

  function abortUpdateTaxNumber() {
    if (abortController) {
      abortController.abort()
      abortController = null
    }
  }

  onUnmounted(abortUpdateTaxNumber)

  return useMutation<boolean, DefaultError, UpdateTaxDetails>({
    mutationKey: ['update-tax-details'],
    mutationFn: (body: UpdateTaxDetails) => {
      abortUpdateTaxNumber()
      abortController = new AbortController()
      return useApiFetch()<string>('/api/payments/tax_details', {
        body,
        method: 'PUT',
        signal: abortController.signal,
      })
    },
  })
}

export function useFullTaxNumberMutation() {
  let abortController: AbortController | null = null

  function abortGetFullTaxNumber() {
    if (abortController) {
      abortController.abort()
      abortController = null
    }
  }

  onUnmounted(abortGetFullTaxNumber)

  return useMutation<string, DefaultError, undefined>({
    mutationKey: ['fetch-tax-details'],
    mutationFn: () => {
      abortGetFullTaxNumber()
      abortController = new AbortController()
      return useApiFetch()<string>('/api/payments/tax_details', {
        signal: abortController.signal,
      })
    },
  })
}
