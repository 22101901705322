<script lang="ts" setup>
import { Btn, Icon, LoadingSpinner } from 'webcc-ui-components'

const emit = defineEmits<{
  (e: 'click', showLanguageList: boolean): void
}>()

const { locales, locale } = useI18n()
const activeElement = useActiveElement()

const [showLanguageList, toggleLanguageList] = useToggle()

const localeName = computed(
  () => toValue(locales).find((i) => i.code === toValue(locale))?.name,
)

function toggleLanguage() {
  toggleLanguageList()
  activeElement.value?.blur()
  emit('click', showLanguageList.value)
}
</script>

<template>
  <div
    class="flex items-center md:block"
    :class="{ 'relative z-10': showLanguageList }"
  >
    <OnDevices rule="<md">
      <LanguageButtonIcon
        :is-active="showLanguageList"
        :locale="locale"
        @click="toggleLanguage"
      />

      <LazyLanguageModal
        v-if="showLanguageList"
        :title="$t('components.common.language')"
        :locales="locales"
        :locale="locale"
        @click="toggleLanguage"
        @close="toggleLanguageList"
      />
    </OnDevices>

    <OnDevices rule=">=md">
      <Btn
        data-id="language-button"
        outlined
        class="w-52 border-bgr-300 text-txt-400"
        size="lg"
        variant="neutral"
        @click="toggleLanguage"
      >
        <div class="flex items-center justify-between">
          <div class="text-txt-400">{{ localeName }}</div>
          <LoadingSpinner
            v-if="showLocaleSpinner"
            size="sm"
            class="[&&]:justify-end [&&]:w-fit"
          />
          <div v-else class="flex items-center gap-2">
            <span class="text-sm uppercase text-txt-400"> {{ locale }}</span>
            <Icon graphic="arrow-down" size="sm" class="text-txt-400" />
          </div>
        </div>
      </Btn>

      <div v-if="showLanguageList">
        <div class="fixed inset-0 z-10 h-screen w-full bg-transparent" />

        <div
          class="absolute z-20 w-full rounded border border-txt-700 bg-white"
        >
          <LanguageButtonList
            :locales
            :locale
            @click="toggleLanguage"
            @close="toggleLanguageList"
          />
        </div>
      </div>
    </OnDevices>
  </div>
</template>
