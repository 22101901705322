<script setup lang="ts">
import { IconBtn } from 'webcc-ui-components'

const { $tracking } = useNuxtApp()
const [isOpen, togglePanel] = useToggle()

function openPanel() {
  $tracking.trackSupportCenter({
    action: TrackAction.Open,
    label: getI18nLabel('components.common.supportCenter'),
  })
  togglePanel()
}
</script>

<template>
  <div class="flex support-center-button">
    <IconBtn
      data-id="support-center-button-mobile"
      icon="chat-question"
      variant="neutral"
      size="md"
      :is-active="isOpen"
      @click="openPanel"
    />

    <BasicPanel
      :title="$t('components.common.supportCenter')"
      :open="isOpen"
      @close="togglePanel(false)"
    >
      <SupportCenterMenu
        class="grid gap-y-2 px-10 py-8 md:min-w-[375px]"
        @click="togglePanel(false)"
      />
    </BasicPanel>
  </div>
</template>
