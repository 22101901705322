import type { FallbackLocale } from 'vue-i18n'
import sustainability from '~/composables/tracking/sustainability'
import myRent from '~/composables/tracking/my-rent'
import feature from '~/composables/tracking/feature'
import profile from '~/composables/tracking/profile'

// Reexport existing composable
export * from './contract-prices-tracking'
export * from './header-tracking'
export * from './login-tracking'
export * from './payments-overview-tracking'
export * from './payments-statements-popup-tracking'

// Enum for reusable action
export enum TrackAction {
  Open = 'open',
  Select = 'select',
  View = 'view',
  Edit = 'edit',
  Update = 'update',
  Navigate = 'navigate',
  BannerClick = 'banner click',
  Back = 'back',
}

export type TrackEvent = Record<string, unknown>

export function useGtm() {
  return useNuxtApp().$gtm
}

export function getDefaultTrackingSettings() {
  const { $i18n } = useNuxtApp()
  const route = useRoute()
  return {
    category: undefined,
    position: route.path,
    language: unref($i18n.locale),
    _clear: true,
  }
}

export function getI18nLabel(key: string, params?: { [key: string]: string }) {
  const { $i18n } = useNuxtApp()
  const fallbackLocaleMessages = $i18n.getLocaleMessage(
    $i18n.fallbackLocale.value as string,
  )
  const variables: { [key: string]: FallbackLocale } = {}

  if (params) {
    Object.keys(params).forEach((key) => {
      variables[key] = import.meta.dev
        ? fallbackLocaleMessages[params[key]].body?.static
        : fallbackLocaleMessages[params[key]].b?.s
    })
  }

  return $i18n.rt(fallbackLocaleMessages[key], 0, { named: variables })
}

export function track(trackEvent: TrackEvent): void {
  useGtm().trackEvent({
    ...getDefaultTrackingSettings(),
    ...trackEvent,
  })
}

const tracking = {
  sustainability,
  myRent,
  feature,
  profile,
}

export default tracking
