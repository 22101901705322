import { default as indexyjFI9v7sfQMeta } from "/myapp/src/pages/2fa/contact-us/error/index.vue?macro=true";
import { default as indexZXYbnq3mZjMeta } from "/myapp/src/pages/2fa/contact-us/index.vue?macro=true";
import { default as index5967NTsq13Meta } from "/myapp/src/pages/2fa/contact-us/success/index.vue?macro=true";
import { default as indexd7MfuvsOJ6Meta } from "/myapp/src/pages/2fa/index.vue?macro=true";
import { default as authentication_45errorR2DeZn2RwFMeta } from "/myapp/src/pages/authentication-error.vue?macro=true";
import { default as calendar85D2jOBqJJMeta } from "/myapp/src/pages/bookings/calendar.vue?macro=true";
import { default as indexGUI4UZCYJyMeta } from "/myapp/src/pages/change-password-confirmation/index.vue?macro=true";
import { default as contact_45listVZPejiCLF4Meta } from "/myapp/src/pages/contact-list.vue?macro=true";
import { default as indexFeJQz9g03ZMeta } from "/myapp/src/pages/contact-us/error/index.vue?macro=true";
import { default as indexI430pJOgFHMeta } from "/myapp/src/pages/contact-us/index.vue?macro=true";
import { default as indexBWe2iWLI5NMeta } from "/myapp/src/pages/contact-us/success/index.vue?macro=true";
import { default as _91_46_46_46slug_934rBU5fFjipMeta } from "/myapp/src/pages/contract-prices/[...slug].vue?macro=true";
import { default as contract_45pricesMTtAkmUvOAMeta } from "/myapp/src/pages/contract-prices.vue?macro=true";
import { default as _91accommodationCode_93J53hEEya3GMeta } from "/myapp/src/pages/document/downloads/[accommodationCode].vue?macro=true";
import { default as indexTTQOe1MnbSMeta } from "/myapp/src/pages/forgot-number/index.vue?macro=true";
import { default as indexHr4UxRfXl5Meta } from "/myapp/src/pages/forgot-number/success/index.vue?macro=true";
import { default as indexlH7vJRCPd3Meta } from "/myapp/src/pages/forgot-password/index.vue?macro=true";
import { default as indexYn2TY8slmUMeta } from "/myapp/src/pages/forgot-password/success/index.vue?macro=true";
import { default as inbox5qQDcOXVwtMeta } from "/myapp/src/pages/inbox.vue?macro=true";
import { default as indexDEpi8hBTECMeta } from "/myapp/src/pages/index.vue?macro=true";
import { default as loginm86YIU8HANMeta } from "/myapp/src/pages/login.vue?macro=true";
import { default as opportunitiesXoCv9Joc9eMeta } from "/myapp/src/pages/opportunities.vue?macro=true";
import { default as indexg2wdv0Avt2Meta } from "/myapp/src/pages/payments-overview/index.vue?macro=true";
import { default as statementshyvyW813LqMeta } from "/myapp/src/pages/payments-overview/statements.vue?macro=true";
import { default as change_45addresszf3u8CbJLJMeta } from "/myapp/src/pages/profile/change-address.vue?macro=true";
import { default as change_45passwordK828mZKX6sMeta } from "/myapp/src/pages/profile/change-password.vue?macro=true";
import { default as change_45phoneFAhwei7JXfMeta } from "/myapp/src/pages/profile/change-phone.vue?macro=true";
import { default as indexzXm4s5EtJnMeta } from "/myapp/src/pages/profile/index.vue?macro=true";
import { default as _91type_93_45taxZtfda0UJJMMeta } from "/myapp/src/pages/profile/legal/[type]-tax.vue?macro=true";
import { default as indexrYuWUo4pyyMeta } from "/myapp/src/pages/profile/legal/index.vue?macro=true";
import { default as notificationsWqoKLEhnFFMeta } from "/myapp/src/pages/profile/notifications.vue?macro=true";
import { default as change_45bank_45detailsIcMoNelAlyMeta } from "/myapp/src/pages/profile/payment-info/change-bank-details.vue?macro=true";
import { default as indexmGSz8gvASuMeta } from "/myapp/src/pages/profile/payment-info/index.vue?macro=true";
import { default as profilemlUecctOxcMeta } from "/myapp/src/pages/profile.vue?macro=true";
import { default as _91_46_46_46slug_93DgCgF7ag9AMeta } from "/myapp/src/pages/property-benchmark/[...slug].vue?macro=true";
import { default as property_45benchmarkgByjBwALFAMeta } from "/myapp/src/pages/property-benchmark.vue?macro=true";
import { default as _91type_939TELAIIC38Meta } from "/myapp/src/pages/reservations/[type].vue?macro=true";
import { default as index70rfn4NFv4Meta } from "/myapp/src/pages/reset-password/index.vue?macro=true";
import { default as reviews2UeSJymRjOMeta } from "/myapp/src/pages/reviews.vue?macro=true";
import { default as tax_45idYCs4K1TEf0Meta } from "/myapp/src/pages/tax-id.vue?macro=true";
export default [
  {
    name: "2fa-contact-us-error",
    path: "/2fa/contact-us/error",
    meta: indexyjFI9v7sfQMeta || {},
    component: () => import("/myapp/src/pages/2fa/contact-us/error/index.vue")
  },
  {
    name: "2fa-contact-us",
    path: "/2fa/contact-us",
    meta: indexZXYbnq3mZjMeta || {},
    component: () => import("/myapp/src/pages/2fa/contact-us/index.vue")
  },
  {
    name: "2fa-contact-us-success",
    path: "/2fa/contact-us/success",
    meta: index5967NTsq13Meta || {},
    component: () => import("/myapp/src/pages/2fa/contact-us/success/index.vue")
  },
  {
    name: "2fa",
    path: "/2fa",
    meta: indexd7MfuvsOJ6Meta || {},
    component: () => import("/myapp/src/pages/2fa/index.vue")
  },
  {
    name: "authentication-error",
    path: "/authentication-error",
    meta: authentication_45errorR2DeZn2RwFMeta || {},
    component: () => import("/myapp/src/pages/authentication-error.vue")
  },
  {
    name: "bookings-calendar",
    path: "/bookings/calendar",
    meta: calendar85D2jOBqJJMeta || {},
    component: () => import("/myapp/src/pages/bookings/calendar.vue")
  },
  {
    name: "change-password-confirmation",
    path: "/change-password-confirmation",
    meta: indexGUI4UZCYJyMeta || {},
    component: () => import("/myapp/src/pages/change-password-confirmation/index.vue")
  },
  {
    name: "contact-list",
    path: "/contact-list",
    meta: contact_45listVZPejiCLF4Meta || {},
    component: () => import("/myapp/src/pages/contact-list.vue")
  },
  {
    name: "contact-us-error",
    path: "/contact-us/error",
    meta: indexFeJQz9g03ZMeta || {},
    component: () => import("/myapp/src/pages/contact-us/error/index.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    meta: indexI430pJOgFHMeta || {},
    component: () => import("/myapp/src/pages/contact-us/index.vue")
  },
  {
    name: "contact-us-success",
    path: "/contact-us/success",
    meta: indexBWe2iWLI5NMeta || {},
    component: () => import("/myapp/src/pages/contact-us/success/index.vue")
  },
  {
    name: "contract-prices",
    path: "/contract-prices",
    meta: contract_45pricesMTtAkmUvOAMeta || {},
    component: () => import("/myapp/src/pages/contract-prices.vue"),
    children: [
  {
    name: "contract-prices-slug",
    path: ":slug(.*)*",
    meta: _91_46_46_46slug_934rBU5fFjipMeta || {},
    component: () => import("/myapp/src/pages/contract-prices/[...slug].vue")
  }
]
  },
  {
    name: "document-downloads-accommodationCode",
    path: "/document/downloads/:accommodationCode()",
    meta: _91accommodationCode_93J53hEEya3GMeta || {},
    component: () => import("/myapp/src/pages/document/downloads/[accommodationCode].vue")
  },
  {
    name: "forgot-number",
    path: "/forgot-number",
    meta: indexTTQOe1MnbSMeta || {},
    component: () => import("/myapp/src/pages/forgot-number/index.vue")
  },
  {
    name: "forgot-number-success",
    path: "/forgot-number/success",
    meta: indexHr4UxRfXl5Meta || {},
    component: () => import("/myapp/src/pages/forgot-number/success/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: indexlH7vJRCPd3Meta || {},
    component: () => import("/myapp/src/pages/forgot-password/index.vue")
  },
  {
    name: "forgot-password-success",
    path: "/forgot-password/success",
    meta: indexYn2TY8slmUMeta || {},
    component: () => import("/myapp/src/pages/forgot-password/success/index.vue")
  },
  {
    name: "inbox",
    path: "/inbox",
    meta: inbox5qQDcOXVwtMeta || {},
    component: () => import("/myapp/src/pages/inbox.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexDEpi8hBTECMeta || {},
    component: () => import("/myapp/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginm86YIU8HANMeta || {},
    component: () => import("/myapp/src/pages/login.vue")
  },
  {
    name: "opportunities",
    path: "/opportunities",
    meta: opportunitiesXoCv9Joc9eMeta || {},
    component: () => import("/myapp/src/pages/opportunities.vue")
  },
  {
    name: "payments-overview",
    path: "/payments-overview",
    meta: indexg2wdv0Avt2Meta || {},
    component: () => import("/myapp/src/pages/payments-overview/index.vue")
  },
  {
    name: "payments-overview-statements",
    path: "/payments-overview/statements",
    meta: statementshyvyW813LqMeta || {},
    component: () => import("/myapp/src/pages/payments-overview/statements.vue")
  },
  {
    name: profilemlUecctOxcMeta?.name,
    path: "/profile",
    meta: profilemlUecctOxcMeta || {},
    component: () => import("/myapp/src/pages/profile.vue"),
    children: [
  {
    name: "profile-change-address",
    path: "change-address",
    component: () => import("/myapp/src/pages/profile/change-address.vue")
  },
  {
    name: "profile-change-password",
    path: "change-password",
    component: () => import("/myapp/src/pages/profile/change-password.vue")
  },
  {
    name: "profile-change-phone",
    path: "change-phone",
    component: () => import("/myapp/src/pages/profile/change-phone.vue")
  },
  {
    name: "profile",
    path: "",
    component: () => import("/myapp/src/pages/profile/index.vue")
  },
  {
    name: "profile-legal-type-tax",
    path: "legal/:type()-tax",
    meta: _91type_93_45taxZtfda0UJJMMeta || {},
    component: () => import("/myapp/src/pages/profile/legal/[type]-tax.vue")
  },
  {
    name: "profile-legal",
    path: "legal",
    meta: indexrYuWUo4pyyMeta || {},
    component: () => import("/myapp/src/pages/profile/legal/index.vue")
  },
  {
    name: "profile-notifications",
    path: "notifications",
    component: () => import("/myapp/src/pages/profile/notifications.vue")
  },
  {
    name: "profile-payment-info-change-bank-details",
    path: "payment-info/change-bank-details",
    meta: change_45bank_45detailsIcMoNelAlyMeta || {},
    component: () => import("/myapp/src/pages/profile/payment-info/change-bank-details.vue")
  },
  {
    name: "profile-payment-info",
    path: "payment-info",
    meta: indexmGSz8gvASuMeta || {},
    component: () => import("/myapp/src/pages/profile/payment-info/index.vue")
  }
]
  },
  {
    name: "property-benchmark",
    path: "/property-benchmark",
    meta: property_45benchmarkgByjBwALFAMeta || {},
    component: () => import("/myapp/src/pages/property-benchmark.vue"),
    children: [
  {
    name: "property-benchmark-slug",
    path: ":slug(.*)*",
    meta: _91_46_46_46slug_93DgCgF7ag9AMeta || {},
    component: () => import("/myapp/src/pages/property-benchmark/[...slug].vue")
  }
]
  },
  {
    name: "reservations-type",
    path: "/reservations/:type()",
    meta: _91type_939TELAIIC38Meta || {},
    component: () => import("/myapp/src/pages/reservations/[type].vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: index70rfn4NFv4Meta || {},
    component: () => import("/myapp/src/pages/reset-password/index.vue")
  },
  {
    name: "reviews",
    path: "/reviews",
    meta: reviews2UeSJymRjOMeta || {},
    component: () => import("/myapp/src/pages/reviews.vue")
  },
  {
    name: "tax-id",
    path: "/tax-id",
    meta: tax_45idYCs4K1TEf0Meta || {},
    component: () => import("/myapp/src/pages/tax-id.vue")
  }
]