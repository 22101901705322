import { intlFormat } from 'date-fns'

export const WEB_PUSH_PREFIX = 'webpush:'

export const ONE_AND_HALF_YEAR_MILLISECONDS = 47335428000

export type TagVariant =
  | 'cta'
  | 'warning'
  | 'highlight'
  | 'info'
  | 'neutral'
  | 'theme'
  | 'error'
  | 'success'

export type BreadcrumbItem = {
  label: string
  url?: string
}

export type DateRange = {
  from: Date | null
  to: Date | null
}

export type DateRangeDefault = {
  start: Date | null
  end: Date | null
}

export type Field = {
  label: string
  value: string
}

export type Tag = { variant: TagVariant; title: string }

export const DATE_FORMAT_DDMMYYYY: Parameters<typeof intlFormat>[1] = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
}
