import revive_payload_client_ioO55Dgb0q from "/myapp/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@4.21.1_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_gHtdFFMJ4n from "/myapp/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@4.21.1_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_wQT8ee4W3q from "/myapp/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@4.21.1_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import auth_plugin_SDs6qgbn1j from "/myapp/src/modules/auth/runtime/auth.plugin.ts";
import runtime_plugin_6fa86710_8fsBHu8DZs from "/myapp/.nuxt/runtime.plugin.6fa86710.ts";
import consent_plugin_OrpQzVK0m0 from "/myapp/src/modules/consent/runtime/consent.plugin.ts";
import route_bQWBGeaCfs from "/myapp/src/plugins/route.ts";
import payload_client_AISH4eMVuq from "/myapp/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@4.21.1_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_DnWjfGiHgb from "/myapp/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@4.21.1_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ZIiN2TPWWC from "/myapp/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@4.21.1_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_eG0D8554xE from "/myapp/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@4.21.1_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/myapp/.nuxt/components.plugin.mjs";
import prefetch_client_63yaDEZOyY from "/myapp/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@4.21.1_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nps_plugin_ACUqMvSpx6 from "/myapp/src/modules/nps/runtime/nps.plugin.ts";
import pull_to_refresh_client_3iAGLApR6u from "/myapp/src/modules/pwa/runtime/pull-to-refresh.client.ts";
import webpush_plugin_UOAqjYppRY from "/myapp/.nuxt/webpush.plugin.js";
import banner_plugin_client_f7qDt2BrYH from "/myapp/src/modules/pwa/runtime/banner.plugin.client.ts";
import pwa_icons_plugin_LnrhIJeMG7 from "/myapp/.nuxt/pwa-icons-plugin.ts";
import pwa_client_YzuA05Z6eZ from "/myapp/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_@vite-pwa+assets-generator@0.2.6_rollup@4.21.1_vite@5.4.8_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import unleash_plugin_client_z11R0dBxXd from "/myapp/src/modules/unleash/runtime/unleash.plugin.client.ts";
import switch_locale_path_ssr_Y3SKvksoqh from "/myapp/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.21.1_vue@3.5.11/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_XtUCIVR88q from "/myapp/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.21.1_vue@3.5.11/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import error_t9vrANEheK from "/myapp/src/plugins/error.ts";
import gtm_JE2y3coDIz from "/myapp/src/plugins/gtm.ts";
import hydration_client_7uir9Lpz2B from "/myapp/src/plugins/hydration.client.ts";
import i18n_client_BwC1oaCAEG from "/myapp/src/plugins/i18n.client.ts";
import sentry_client_KAXFuL2wum from "/myapp/src/plugins/sentry.client.ts";
import sse_qpzEVq9hwa from "/myapp/src/plugins/sse.ts";
import toast_ammHSktxCk from "/myapp/src/plugins/toast.ts";
import touch_clfFFfMVxr from "/myapp/src/plugins/touch.ts";
import vue_query_JC9gnf7CA1 from "/myapp/src/plugins/vue-query.ts";
import auth_events_mgogFCKzZc from "/myapp/src/plugins/auth-events.ts";
import date_LNdv6DCjtO from "/myapp/src/plugins/date.ts";
import remember_me_o8wXFBwayj from "/myapp/src/plugins/remember-me.ts";
import tracking_client_6yZzhGFSmD from "/myapp/src/plugins/tracking.client.ts";
import translations_keys_xEeDYLqZP6 from "/myapp/src/plugins/translations-keys.ts";
export default [
  revive_payload_client_ioO55Dgb0q,
  unhead_gHtdFFMJ4n,
  router_wQT8ee4W3q,
  auth_plugin_SDs6qgbn1j,
  runtime_plugin_6fa86710_8fsBHu8DZs,
  consent_plugin_OrpQzVK0m0,
  route_bQWBGeaCfs,
  payload_client_AISH4eMVuq,
  navigation_repaint_client_DnWjfGiHgb,
  check_outdated_build_client_ZIiN2TPWWC,
  chunk_reload_client_eG0D8554xE,
  components_plugin_KR1HBZs4kY,
  prefetch_client_63yaDEZOyY,
  nps_plugin_ACUqMvSpx6,
  pull_to_refresh_client_3iAGLApR6u,
  webpush_plugin_UOAqjYppRY,
  banner_plugin_client_f7qDt2BrYH,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_YzuA05Z6eZ,
  unleash_plugin_client_z11R0dBxXd,
  switch_locale_path_ssr_Y3SKvksoqh,
  i18n_XtUCIVR88q,
  error_t9vrANEheK,
  gtm_JE2y3coDIz,
  hydration_client_7uir9Lpz2B,
  i18n_client_BwC1oaCAEG,
  sentry_client_KAXFuL2wum,
  sse_qpzEVq9hwa,
  toast_ammHSktxCk,
  touch_clfFFfMVxr,
  vue_query_JC9gnf7CA1,
  auth_events_mgogFCKzZc,
  date_LNdv6DCjtO,
  remember_me_o8wXFBwayj,
  tracking_client_6yZzhGFSmD,
  translations_keys_xEeDYLqZP6
]